exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-calculator-tsx": () => import("./../../../src/pages/calculator.tsx" /* webpackChunkName: "component---src-pages-calculator-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-faqs-tsx": () => import("./../../../src/pages/faqs.tsx" /* webpackChunkName: "component---src-pages-faqs-tsx" */),
  "component---src-pages-how-it-works-tsx": () => import("./../../../src/pages/how-it-works.tsx" /* webpackChunkName: "component---src-pages-how-it-works-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

